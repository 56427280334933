<template>
    <div class="managsystem-registration-ranking">
        <div class="block-box">
            <div class="head-box">
                <div class="title">各社区注册率排行</div>
                <div class="date-box">
                    <Icon type="md-calendar" />
                    <p class="date">数据更新时间：{{ $core.formatDate(new Date(), "yyyy-MM-dd 00:00") }}</p>
                </div>
                <div class="operate-box">
                    <div class="setting-btn" @click="onDisplaySetting">
                        <Icon type="md-settings" />
                        <p class="name">设置</p>
                    </div>
                </div>
            </div>
            <div class="content-box">
                <div class="table-box">
                    <ChildrensTable :tableColumn="tableColumn" :tableData="tableData"></ChildrensTable>
                </div>
            </div>
        </div>

        <CompModal ref="comp_modal" title="设置" width="900px" @on-close="getCommunityData">
            <Table :columns="settingTableColumn" :data="tableData"></Table>
        </CompModal>
    </div>
</template>

<script>
import CompModal from "@/views/residentdatabase/components/CompModal.vue"
import ChildrensTable from "./childrens/Table.vue"

import Street from "./utils/street"
import DateUtil from "./utils/date"

export default {
    components: { ChildrensTable, CompModal },

    data() {
        return {
            date: DateUtil.getDateByDay(-1),

            tableColumn: [
                {
                    title: "注册率排行",
                    minWidth: 100,
                    align: "center",
                    render: (h, params) => {
                        return h("p", "No." + (params.index + 1))
                    },
                },
                {
                    title: "类型",
                    minWidth: 100,
                    align: "center",
                    render: (h, params) => {
                        if (params.row.onlineStatus === "1") {
                            return h("p", null, ({ 1: "市级", 2: "区级", 3: "街镇级" }[params.row.onlineType] || "") + "示范社区")
                        }
                        return h("p", null, { 2: "试用社区", 3: "非试用社区" }[params.row.onlineStatus] || "-")
                    },
                },
                {
                    title: "社区",
                    key: "orgName",
                    minWidth: 100,
                    align: "center",
                },
                {
                    title: "户籍人数&常住人员数",
                    key: "shouldMember",
                    minWidth: 150,
                    align: "center",
                },
                {
                    title: "当前累计注册用户数",
                    key: "statRegister",
                    minWidth: 150,
                    align: "center",
                },
                {
                    title: "注册率",
                    minWidth: 100,
                    align: "center",
                    render: (h, params) => {
                        return h("p", (params.row.registrationRate || 0) + "%")
                    },
                },
            ],

            tableData: [],

            settingTableColumn: [
                {
                    title: "序号",
                    type: "index",
                    width: 80,
                    align: "center",
                    fixed: "left",
                },
                {
                    title: "类型",
                    minWidth: 100,
                    align: "center",
                    render: (h, params) => {
                        return h("p", null, Street.getCommunityType(params.row) || "-")
                    },
                },
                {
                    title: "社区",
                    key: "orgName",
                    minWidth: 100,
                    align: "center",
                },
                {
                    title: "户籍人数&常住人员数",
                    minWidth: 180,
                    align: "center",
                    render: (h, params) => {
                        return h("div", [
                            h("Input", {
                                props: {
                                    value: params.row.shouldMember,
                                },
                                attrs: {
                                    type: "number",
                                    number: "true",
                                },
                                style: {
                                    width: "100px",
                                },
                                on: {
                                    "on-change": evt => {
                                        params.row.shouldMember = evt.target.value
                                    },
                                },
                            }),
                            h(
                                "Button",
                                {
                                    attrs: {
                                        type: "info",
                                    },
                                    style: {
                                        marginLeft: "10px",
                                    },
                                    on: {
                                        click: () => {
                                            this.onChangeShouldMember(params.row.orgCode, params.row.shouldMember)
                                        },
                                    },
                                },
                                "确认"
                            ),
                        ])
                    },
                },
            ],
        }
    },

    async mounted() {
        let street = await Street.getInfo()
        this.orgPath = street.orgPath
        this.getCommunityData()
    },

    methods: {
        /**
         * 显示设置页面
         */
        onDisplaySetting() {
            this.$refs.comp_modal.display()
        },

        getCommunityData() {
            this.$Message.loading({
                content: "加载中...",
                duration: 0,
            })

            return this.$post(
                "/gateway/api/ck/OrgIndicator/listCommunityValue",
                {
                    dataScopeId: this.orgPath, // 范围编码
                    statDate: this.$core.formatDate(this.date, "yyyy-MM-dd"), // 统计时间
                    selectedField: ["orgCode", "orgName", "onlineType", "onlineStatus", "shouldMember", "statRegister", "registrationRate"], // 查询字段
                },
                { "Content-Type": "application/json" }
            )
                .then(res => {
                    if (res.code == 200) {
                        this.tableData = res.dataList?.sort((a, b) => {
                            let na = Number(a.registrationRate)
                            let nb = Number(b.registrationRate)

                            if (na === nb) return 0
                            return na < nb ? 1 : -1
                        })
                    }
                })
                .finally(this.$Message.destroy)
        },

        /**
         * 修改常住人口事件
         */
        onChangeShouldMember(orgCode, value) {
            return this.$post("/gateway/api/symanage/pc/orgOnline/updateShouldMember", {
                orgCode: orgCode,
                shouldMember: value,
            })
                .then(res => {
                    setTimeout(() => {
                        if (res.code == 200) {
                            this.$Message.success("修改成功，数据将于次日更新！")
                        } else {
                            this.$Message.error(res.desc)
                        }
                    })
                })
                .finally(this.$Message.destroy)
        },
    },
}
</script>

<style lang="less">
.managsystem-registration-ranking {
    background: #f8f8f8;
    position: relative;
    min-height: 100%;

    .block-box {
        width: 100%;
        padding: 0 20px 20px;
        box-sizing: border-box;
        max-width: 1500px;
        margin: 0 auto;

        &:first-child {
            padding-top: 20px;
        }

        > .head-box {
            margin-bottom: 10px;
            display: flex;
            align-items: center;

            .title {
                font-size: 20px;
                font-weight: bold;
                color: #333;
            }

            .date-box {
                margin: 0 20px;
                background: #fff;
                padding: 10px 20px;
                line-height: 1;
                display: flex;
                align-items: center;
                border-radius: 4px;

                .date {
                    margin-left: 10px;
                    color: #666;
                }
            }

            .operate-box {
                flex-grow: 1;
                display: flex;
                align-items: center;
                justify-content: flex-end;

                .setting-btn {
                    cursor: pointer;
                    padding: 10px 20px;
                    background: #fff;
                    border-radius: 4px;
                    line-height: 1;
                    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.02);
                    display: flex;
                    align-items: center;

                    .name {
                        margin-left: 10px;
                    }
                }
            }
        }

        .content-box {
            width: 100%;

            .table-box {
                margin: 20px 0;
                padding: 10px;
                height: 500px;
                background: #fff;
                border-radius: 6px;
                box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08);
                overflow: hidden;
            }
        }
    }
}
</style>
